import { Button } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid-pro";
import { RevivnApiDataGrid } from "components/DataGrid/RevivnApiDataGrid";
import { Link } from "react-router-dom";
import { ProductCatalog } from "types/productCatalog";
import { CheckCircle, Add } from "@mui/icons-material";
import { dateTimeColDef } from "components/DataGrid/columnDefs/dateTimeColDef";

const toPrice = (value: string | number) => Number(value).toFixed(2);
const priceRenderer =
  (field: "startingPrice" | "backmarketPrice" | "reebeloPrice") =>
  (params: GridRenderCellParams<any, ProductCatalog, any>) =>
    params.row[field] ? `$${toPrice(params.row[field])}` : "-";

export default function ProductCatalogsDataGrid() {
  const photosField = (productCatalog: ProductCatalog) => {
    const hasPhotos = productCatalog.hasStockPhotos || productCatalog.hasResalePhotos;

    if (!productCatalog.photosUrl) {
      return null;
    }

    return (
      <Link to={productCatalog.photosUrl} style={{ textDecoration: "none" }}>
        <Button size="small" variant="text" color="primary" startIcon={hasPhotos ? <CheckCircle /> : <Add />}>
          {hasPhotos ? "View Photos" : "Add Photos"}
        </Button>
      </Link>
    );
  };

  const columns: GridColDef<ProductCatalog>[] = [
    { field: "id", headerName: "Product ID", hide: true },
    { field: "merchantProductNumber", headerName: "Merchant Product Number" },
    { field: "quantity", headerName: "Quantity", type: "number" },
    { field: "sku", headerName: "SKU" },
    { field: "inventoryId", headerName: "Phoenix ID" },
    { field: "deviceType", headerName: "Device Type" },
    { field: "inventoryGroup", headerName: "Inventory Group" },
    { field: "backmarketId", headerName: "Backmarket ID" },
    { field: "reebeloId", headerName: "Reebelo ID" },
    {
      field: "startingPriceInCents",
      headerName: "Starting Price",
      renderCell: priceRenderer("startingPrice"),
      type: "number",
    },
    {
      field: "backmarketPriceInCents",
      headerName: "Backmarket Price",
      renderCell: priceRenderer("backmarketPrice"),
      type: "number",
    },
    {
      field: "reebeloPriceInCents",
      headerName: "Reebelo Price",
      renderCell: priceRenderer("reebeloPrice"),
      type: "number",
    },
    { field: "title", headerName: "Title" },
    { field: "subtitle", headerName: "Subtitle", hide: true },
    { field: "description", headerName: "Description", hide: true },
    { field: "categoryId", headerName: "Category ID", hide: true },
    { field: "primaryCategory", headerName: "Primary Category" },
    { field: "secondaryCategory", headerName: "Secondary Category", hide: true },
    { field: "condition", headerName: "Condition" },
    { field: "conditionNote", headerName: "Condition Note", hide: true },
    { field: "brand", headerName: "Brand" },
    { field: "screenSize", headerName: "Screen Size" },
    { field: "processor", headerName: "Processor" },
    { field: "model", headerName: "Model" },
    { field: "releaseYear", headerName: "Release Year" },
    { field: "hardDriveCapacity", headerName: "Hard Drive Capacity", hide: true },
    { field: "color", headerName: "Color" },
    { field: "ram", headerName: "RAM" },
    { field: "storageType", headerName: "Storage Type" },
    { field: "gpu", headerName: "GPU" },
    { field: "processorSpeed", headerName: "Processor Speed" },
    { field: "operatingSystem", headerName: "Operating System" },
    { field: "maximumResolution", headerName: "Maximum Resolution", hide: true },
    { field: "itemHeight", headerName: "Item Height", hide: true },
    { field: "itemWidth", headerName: "Item Width", hide: true },
    { field: "itemLength", headerName: "Item Length", hide: true },
    { field: "itemWeight", headerName: "Item Weight", hide: true },
    { field: "shippingPolicyName", headerName: "Shipping Policy Name", hide: true },
    { field: "daysOnShelf", headerName: "Days On Shelf", hide: true },
    { field: "daysSincePurchase", headerName: "Days Since Purchase", hide: true },
    {
      field: "createdAt",
      headerName: "Created",
      ...dateTimeColDef,
      filterable: false,
      width: 200,
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      ...dateTimeColDef,
      filterable: false,
      width: 200,
    },
    {
      field: "lastQuantitiesSyncedAt",
      headerName: "Last Quantities Sync",
      ...dateTimeColDef,
      filterable: false,
      width: 200,
    },
    {
      field: "lastFieldsSyncedAt",
      headerName: "Last Fields Sync",
      ...dateTimeColDef,
      filterable: false,
      width: 200,
    },
    {
      field: "photosUrl",
      headerName: "Photos",
      renderCell: (params: any) => photosField(params.row),
      filterable: false,
      sortable: false,
      width: 200,
    },
  ];

  return (
    <RevivnApiDataGrid
      sx={{
        backgroundColor: "background.paper",
        minHeight: 600,
        height: "calc(100vh - 10em)",
      }}
      columns={columns}
      getDetailPanelHeight={() => "auto"}
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        filterPanel: { visible: false },
        toolbar: {
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true, fileName: `product_catalogs_${Date.now()}` },
        },
      }}
      url={"product_catalogs"}
    />
  );
}
