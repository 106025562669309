import useDirectUpload from "hooks/useDirectUpload";
import { useRef } from "react";
import { Inventory } from "types/inventory";
import { useAlert } from "./useAlert";
import useInventory from "./useInventory";

export default function useResalePhotos(inventory?: Inventory) {
  const signedIds = useRef<{ filename: string; signed_id: string }[]>([]);
  const prefix = () => "resale_photos/" + (inventory?.id ?? "") + "/";
  const { uploadSync } = useDirectUpload({ keyPrefix: prefix(), service: "amazon_public", useFilename: true });
  const { updateInventory } = useInventory();
  const { alertError, alertSuccess } = useAlert();
  const resalePhotoBoothImageOrder = [
    "hero",
    "keyboard",
    "front_edge",
    "right_edge",
    "left_edge",
    "top_face",
    "top_angle",
    "bottom_face",
    "back_edge",
  ];

  const upload = async (file: File) => {
    return uploadSync(file)
      .then((blob) => {
        signedIds.current.push({ filename: blob.filename, signed_id: blob.signed_id });
        return true;
      })
      .catch((err) => {
        console.log("Upload error:", err);
        return false;
      });
  };

  const uploadFiles = async (files: File[]) => {
    if (!inventory) return Promise.reject("Inventory not assigned.");
    signedIds.current = [];
    const promises = files.map((file) => upload(file));

    return Promise.all(promises).then((result) => {
      const successfulUploads = result.filter((value) => value === true).length;
      const failedUploads = result.length - successfulUploads;
      if (successfulUploads) alertSuccess(`${successfulUploads} Resale Photos uploaded successfully.`);
      if (failedUploads) alertError(`${failedUploads} Resale Photos failed to upload.`);
      if (inventory) {
        const sortedArray = signedIds.current.sort((a, b) => {
          const indexA = resalePhotoBoothImageOrder.indexOf(a.filename.split(".")[0]);
          const indexB = resalePhotoBoothImageOrder.indexOf(b.filename.split(".")[0]);

          return indexA - indexB;
        });
        inventory.resalePhotos.push(...sortedArray);
        updateInventory(inventory).then(({ data }) => {
          inventory = data;
        });
      }
      return result;
    });
  };

  return { uploadFiles };
}
